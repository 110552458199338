var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page h-100 d-flex h-100 flex-column" },
    [
      _c(
        "section",
        {
          staticClass: "border-bottom bg-lighter md-up:sticky-top",
          staticStyle: { top: "70px" }
        },
        [
          _c("div", { staticClass: "container py-3" }, [
            _c(
              "nav",
              { staticClass: "nav sm-down:flex-column" },
              [
                _vm.type === "normal"
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                        attrs: { to: "/round/post" }
                      },
                      [
                        _c("i", { staticClass: "i-package float-left" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("new_round")))])
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                        attrs: { to: "/round/post/longvideo" }
                      },
                      [
                        _c("i", { staticClass: "i-package float-left" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("new_video")))])
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "btn btn-sm btn-light text-left d-block sm-down:w-fill",
                    attrs: { to: "/rounds/" + _vm.type }
                  },
                  [
                    _c("i", { staticClass: "i-grid float-left" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("open_list")))])
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "p-relative py-10",
          staticStyle: { flex: "1", "min-height": "calc(100vh - 195px)" }
        },
        [
          _c(
            "gmap-map",
            {
              ref: "map",
              staticClass: "fill-parent",
              staticStyle: { position: "absolute", top: "0", left: "0" },
              attrs: {
                zoom: 17,
                center: _vm.center,
                options: {
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false
                },
                "map-type-id": "terrain"
              }
            },
            [
              _vm.loaded
                ? _c(
                    "div",
                    _vm._l(_vm.rounds, function(round, i) {
                      return _c("gmap-marker", {
                        key: i,
                        attrs: {
                          clickable: true,
                          icon: _vm.icon(round),
                          position: { lat: round.lat, lng: round.lng }
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/round/" + round.idt_round)
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }