<template>
  <main class="models-page h-100 d-flex h-100 flex-column">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="container py-3">
        <nav class="nav sm-down:flex-column">
          <router-link
            v-if="type === 'normal'"
            to="/round/post"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-package float-left"></i>
            <span>{{ $t("new_round") }}</span>
          </router-link>
          <router-link
            v-else
            to="/round/post/longvideo"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-package float-left"></i>
            <span>{{ $t("new_video") }}</span>
          </router-link>

          <router-link
            :to="'/rounds/' + type"
            class="btn btn-sm btn-light text-left d-block sm-down:w-fill"
          >
            <i class="i-grid float-left"></i>
            <span>{{ $t("open_list") }}</span>
          </router-link>
        </nav>
      </div>
    </section>

    <section
      class="p-relative py-10"
      style="flex: 1; min-height: calc(100vh - 195px);"
    >
      <gmap-map
        ref="map"
        class="fill-parent"
        :zoom="17"
        :center="center"
        style="position: absolute;top: 0;left: 0;"
        :options="{
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        }"
        map-type-id="terrain"
      >
        <div v-if="loaded">
          <gmap-marker
            v-for="(round, i) in rounds"
            :key="i"
            :clickable="true"
            :icon="icon(round)"
            @click="$router.push('/round/' + round.idt_round)"
            :position="{ lat: round.lat, lng: round.lng }"
          />
        </div>
      </gmap-map>
    </section>
  </main>
</template>

<script>
export default {
  name: "RoundsMapPage",
  props: {
    type: String
  },
  data() {
    return {
      rounds: [],
      loaded: false,
      center: {
        lat: 59.9598743,
        lng: 30.2965115
      }
    };
  },
  mounted() {
    this.user_id = this.$route.params.user_id;
    this.business_id = this.$route.params.business_id;
    this.$navbar.name = this.$t("rounds");
    this.loadPage();
    this.$nextTick(() => {
      this.map = this.$refs.map;
      this.$refs.map.$mapPromise.then(() => {
        this.loaded = true;
      });
    });
  },
  methods: {
    icon(round) {
      try {
        return {
          scaledSize: new window["google"].maps.Size(39.38, 70),
          origin: new window["google"].maps.Point(0, 0),
          anchor: new window["google"].maps.Point(0, 0),
          url: round["url_preview"]
        };
      } catch (err) {
        console.log(err);
      }
    },

    loadPage() {
      this.loaded = false;

      let params = {
        idt_user: this.user_id,
        idt_arp: this.$route.params.business_id,
        limit: 500,
        long_video: this.type !== "normal",
        is_location_hidden: true,
        skip_old: true,
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .get("/rounds", { params })
        .then(response => {
          this.length = response.data.count;
          this.$navbar.name = `${this.$t("rounds")} (${this.length})`;

          response.data.rounds.map(item => this.rounds.push(item));
          for (let round in this.rounds) {
            if (this.rounds[round].lat) {
              this.center.lat = this.rounds[round].lat;
              this.center.lng = this.rounds[round].lng;
              break;
            }
          }
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    }
  }
};
</script>
